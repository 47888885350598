// import Header from './component/header';
// import './App.css';
// import "./css/main.css";
// import "./css/bootstrap.css";
// import "./css/main.css.map";
// import "./css/main.scss";
// import "./css/responsive.css";

import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./home";
import About from "./about";
import Department from "./department";
import Ourdoctor from "./ourdoctor";
import Contact from "./contact";
import Client from "./client";
import Footer from "./component/footer";
import Header from "./component/header";
import Demo from "./demo";
import Social from "./component/social";
import Administrators from "./Administratoros";
// import Home from "./pages";
import React,{useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Specialities from "./specialities";
import HealthPackage from "./healthPackage";
import Acadamy from "./Acadamy";
import Preloader from "./component/preloader";



function App() {
  useEffect(() => {
    AOS.init();
  }, [])

  return (
   
    <Router>
   
    <Routes>
        <Route exact path="/" element={<>
        
          <Header />
          <Home  />
          <Social/>
          <Footer />
         
        </>
       } />
        <Route exact path="/About" element={<>
          <Header />
          <Social/>
          <About  />
          <Footer/>
        </>} />
        <Route exact path="/HealthPackage" element={ <><Header /> <Social/><HealthPackage /> <Footer/></>} />
        <Route exact path="/Acadamy" element={ <><Header /> <Social/><Acadamy /> <Footer/></>} />
        <Route exact path="/Specialities" element={ <><Header /> <Social/><Specialities /> <Footer/></>} />
        <Route exact path="/" element={ <><Header /> <Social/><Department /><Footer/></>} />
        <Route exact path="/Ourdoctor" element={ <><Header /> <Social/><Ourdoctor  /> <Footer/></>} />
        <Route exact path="/Contact" element={<><Header /> <Social/><Contact  /> <Footer/></>} />
        {/* <Route exact path="/Client" element={<Client  />} /> */}
        {/* <Route exact path="/Header" element={<Header  />} /> */}
        <Route exact path="/Demo" element={<Demo  />} />
        {/* <Route exact path="/Administrators" element={<><Header /> <Social/><Administrators/><Footer/></>} /> */}

    </Routes>
</Router>
  );
}

export default App;
