import React from 'react';
import { useState } from 'react'
import { Link } from 'react-router-dom';
import logo1 from '../assets/images/logo.png'
import logo2 from '../assets/images/medical-logo12.png'
import { FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

function Footer() {
  const [color, setColor] = useState('white');
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <footer className="footer_section">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 col-lg-4 footer_col">
              <div className="footer_contact">
                <a className="navbar-brand d-flex" href="#" >
                  <div>
                    <img src={logo2} className="logo-img" />
                  </div>

                  <div style={{ Color: color }}>
                    <img src={logo1} className="logo-img1" />
                  </div>
                </a>
                <div className="contact_link_box">
                  <a href="https://www.google.com/maps/place/Sahaya+Annai+Hospital/@9.9629219,78.0983009,15z/data=!4m6!3m5!1s0x3b00c8b1ea10f505:0x5d28a700dfd53adb!8m2!3d9.9629219!4d78.0983009!16s%2Fg%2F11b7y9l4l5?entry=ttu"  target='blank'>
                    <i className="bi bi-geo-alt-fill"></i>
                    <span>
                      Alanganallur Main Road, Chellaiya Nagar, Koodal Nagar, Madurai-625018.
                    </span>
                  </a>
                  <a href="tel:06382617206">
                    <i className="bi bi-telephone-fill"></i>
                    <span>
                      +91- 6382617206
                    </span>
                  </a>
                  <a href="mailto:sahayaannai@123gmail.com">
                    <i className="bi bi-envelope-fill"></i>
                    <span>sahayaannai@123gmail.com</span>
                  </a>
                </div>
              </div>
              <div className="footer_social">
                <a href="">
                  <FaFacebook />
                </a>
                <a href="">
                  <FaTwitter />
                </a>
                <a href="">
                  <FaInstagramSquare />
                </a>
                <a href="">
                  <FaLinkedin />
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-2  footer_col ">
              <div className="footer_link_box">
                <h4>
                  Quick Links
                </h4>
                <div className="footer_links">
                  <Link className=" " to="/">
                    Home
                  </Link>
                  <Link className="" to="/About">
                    About
                  </Link>
                  <Link className="" to="/Specialities">
                    Specialities
                  </Link>
                  <Link className="" to="/Ourdoctor">
                    Doctors
                  </Link>
                  <Link className="" to="/">
                    Health Packages
                  </Link>
                  <Link className="" to="/Acadamy">
                    Academy
                  </Link>
                  <Link className="" to="/Contact">
                    Contact
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-2  footer_col " >


              <div className="footer_link_box">
                <h4>
                  Specialist
                </h4>
                <div className="footer_links">
                  <a className="active" href="">
                    Accident and Emergency Care
                  </a>
                  <a className="" href="">
                    Arthroplasty
                  </a>
                  <a className="" href="">
                    Bariatric surgery
                  </a>
                  <a className="" href="">
                    Cardiothoracic and Vascular Surgery
                  </a>
                  <a className="" href="">
                    Dermatology
                  </a>
                  <a className="" href="">
                    Ent
                  </a>
                  <a className="" href="">
                    Anaesthesiology
                  </a>
                  <a className="" href="">
                    Arthroscopy
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-2  footer_col ">
              <div className="footer_link_box">
                <h4>
                  Specialist
                </h4>
                <div className="footer_links">
                  <a className="active" href="">
                    Cardiology
                  </a>
                  <a className="" href="">
                    Dental Care
                  </a>
                  <a className="" href="">
                    Diabetes and Endocrinology
                  </a>
                  <a className="" href="">
                    Gastroenterology
                  </a>
                  <a className="" href="">
                    Orthopaedics
                  </a>
                  <a className="" href="">
                    Laboratory Medicine
                  </a>
                  <a className="" href="">
                    Neonatology
                  </a>
                  <a className="" href="">
                    Radiology
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-2  footer_col ">
              <div className="footer_link_box">
                <h4>
                  Specialist
                </h4>
                <div className="footer_links ">
                  <a className="active" href=" ">
                    Rheumatology
                  </a>
                  <a className="" href="">
                    Pulmonology
                  </a>
                  <a className="" href="">
                    Plastic-Surgery
                  </a>
                  <a className="" href="">
                    Brain-surgery
                  </a>
                  <a className="" href="">
                    Obstetrics
                  </a>
                  <a className="" href="">
                    Pediatric_Neurology
                  </a>

                </div>
              </div>
            </div>
          </div>
          <div className="footer-info ">
            <p className='text-center'>
              &copy; Copy Right {currentYear} <span id="displayYear"></span>. All Rights Reserved By
              <a href="/" style={{ color: 'white', fontWeight: '600' }}> Sahaya Annai Hospital
              </a>
              <br />
              <br />
              Designed  By
              <a href="https://virtualtechnology.in/" target='blank' style={{ color: 'white', fontWeight: '600', }}> virtualtechnology.in</a>
            </p>

          </div>
        </div>
      </footer>
    </div>
  );
}
export default Footer;