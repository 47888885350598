import React, { useEffect} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import nursing from './assets/images/nursing-ban3.jpg'
import nursing1 from './assets/images/nursing-ban1.jpg'
import nursing2 from './assets/images/nursing-ban2.jpg' 
import nursingabout from './assets/images/nursing-about.jpg'
import gallary1 from './assets/images/gallary1.jpg'
import gallary2 from './assets/images/gallary2.jpg'
import gallary3 from './assets/images/gallary3.jpg'
import gallary4 from './assets/images/gallary4.jpg'
import gallary5 from './assets/images/gallary5.jpg'
import gallary6 from './assets/images/gallary6.jpg'
import gallary7 from './assets/images/gallary7.jpg'
import gallary8 from './assets/images/gallary8.jpg'
import gallary9 from './assets/images/gallary9.jpg'
import gallary10 from './assets/images/gallary10.jpg'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';




const phoneNumber = '6382617206'; // Replace this with the actual phone number
  const message = 'Welcome to Sahaya Annai Community Collage'; // Replace with your desired message 

  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)} `;

 
const Acadamy = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
       <Swiper 
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide className="hero-left ">
          <img src={nursing} />
        </SwiperSlide>
        <SwiperSlide className="hero-left ">
          <img src={nursing1} />
        </SwiperSlide>
        <SwiperSlide className="hero-left ">
          <img src={nursing2} />
        </SwiperSlide>
        
      </Swiper>

      {/* about */}

      <div id="about-acadamy" className="section" data-aos="fade-up-left">
			<div className="container">
				<div className="row">

					<div className="col-xl-6 col-md-12">
						<div className="section-header">
							<h2 className='acadamy-wel'>Nursing Courses</h2>
							<p className="lead">Sahaya Annai Hospital's nursing college offers courses in nursing education with comprehensive curriculum and facilities.</p>
						</div>
						
						<div className="feature-acadamy" data-aos="fade-up-left">
							<i className="feature-icon-acadamy bi bi-pc-display-horizontal"></i>
							<div className="feature-content-acadamy">
								<h4>Computer Courses </h4>
								<p>computer courses covering Basic Computer design, Adobe Photoshop, Adobe Premier, Final Cut Pro, etc...</p>
							</div>
						</div>
					
						<div className="feature-acadamy" data-aos="fade-up-left">
							<i className="feature-icon2-acadamy bi bi-book-half"></i>
							<div className="feature-content-acadamy">
								<h4>1 Year Diploma Courses</h4>
                <ul className='list-disc'>
                  <li>100% Pass</li>
                  <li>Immediate Employment</li>
                  <li>Accommodation is Available</li>
                </ul>
							</div>
						</div>
					
						<div className="feature-acadamy" data-aos="fade-up-left">
							<i className="feature-icon-acadamy bi bi-lightbulb-fill"></i>
							<div className="feature-content-acadamy">
								<h4>Eligibility for Training</h4>
                <ul className='list-disc'>
                <li>Age 15 to 35 ( Married/ Unmarried Females)</li>
                <li>10th (pass) and 12th or above (only for Women)</li>
                </ul>
								
							</div>
						</div>
						

					</div>

					<div className="col-xl-6 col-md-12 " data-aos="fade-up-right">
						<div className="about-acadamy-img">
							<img src={nursingabout} alt="" />
						</div>
					</div>

				</div>
				

			</div>
		
		</div>

		{/* gallary */}
		<div className="filter-gallery container" data-aos="fade-up"
     data-aos-duration="3000">
        <h1 className='acadamy-gallary'>Gallery</h1>

        <div className="image-containerAcadamy">
            <div className="gallery-item" data-category="nature" >
                <img src={gallary1} alt="Image 1" />
            </div> 

            <div className="gallery-item" data-category="animals">
                <img src={gallary2} alt="Image 2" />
            </div>
           
            <div className="gallery-item" data-category="nature">
                <img src={gallary3} alt="Image 3" />
            </div>

            <div className="gallery-item" data-category="animals">
                <img src={gallary4} alt="Image 4" />
            </div>

            <div className="gallery-item" data-category="nature">
                <img src={gallary5} alt="Image 5" />
            </div>

            <div className="gallery-item" data-category="nature">
                <img src={gallary6} alt="Image 6" />
            </div>
           
            <div className="gallery-item" data-category="animals">
                <img src={gallary7} alt="Image 7" />
            </div>
            <div className="gallery-item" data-category="animals">
                <img src={gallary8} alt="Image 7" />
            </div>
            <div className="gallery-item" data-category="animals">
                <img src={gallary9} alt="Image 7" />
            </div>
            <div className="gallery-item" data-category="animals">
                <img src={gallary10} alt="Image 7" />
            </div>
            
         
           
        </div>
    </div>

{/* Acadamy contcat us */}

    {/* <div className="containerAcadamy">
      <span className="big-circle"></span>
      <img src="img/shape.png" className="square" alt="" />
      <div className="formAcadamy  ">
        <div className="contact-infoAcadamy">
          <h3 className="titleAcadamyus">Let's get in touch</h3>
          <p className="text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
            dolorum adipisci recusandae praesentium dicta!
          </p>

          <div className="info">
            <div className="informationAcadamy">
              <i className="fas fa-map-marker-alt"></i> &nbsp &nbsp

              <p>92 Cherry Drive Uniondale, NY 11553</p>
            </div>
            <div className="informationAcadamy">
              <i className="fas fa-envelope"></i> &nbsp &nbsp
              <p>lorem@ipsum.com</p>
            </div>
            <div className="informationAcadamy">
              <i className="fas fa-phone"></i>&nbsp&nbsp
              <p>123-456-789</p>
            </div>
          </div>

          <div className="social-mediaAcadamy">
            <p>Connect with us :</p>
            <div className="social-iconsAcadamy">
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="contact-formAcadamy">
          <span className="circle one"></span>
          <span className="circle two"></span>

          
        </div>

          

      </div>
    </div> */}
 
 <section id="contact" className="contactAcadamy" >
        <div className="container  px-lg-5 px-0" data-aos="fade-up">
    
          <div>
            <h1 className="acadamyus-header">Contact</h1>
          </div>
    
          <div className="row gx-lg-0 gy-4">
    
            <div className="col-lg-5 col-md-12">
    
              <div className="info-containerAcadamy d-flex flex-column align-items-center justify-content-center">
                 <div className="info-itemAcadamy d-flex">
                  <i className="bi bi-geo-alt flex-shrink-0"></i>
                  <div>
                    <h4>Location :</h4>
                    <p>Sahaya Annai Community College, Chellaiya Nagar, Koodal Nagar, Madurai.</p>
                  </div>
                </div> 
              
                {/* <div className="d-flex contact-submit">
                  <img src="./assets/img/contact-call.png" />
                </div> */}
    
                {/* <div className="info-itemAcadamy d-flex">
                  <i className="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h4>Email:</h4>
                    <p>abselectrolysis@gmail.com</p>
                  </div>
                </div> */}
    
                <div className="info-itemAcadamy d-flex">
                  <i className="bi bi-phone flex-shrink-0"></i>
                  <div>
                    <h4>Call :</h4>
                    {/* <p>+91 4526912324</p> */} <p><a href="tel:4526912324">+91 4526912324</a></p>
                  </div>
                </div>
    
                <div className="info-itemAcadamy d-flex">
                  <i className="bi bi-whatsapp flex-shrink-0"></i>
                  <div>
                    <h4>WhatsApp :</h4>
                    <p><a href={whatsappUrl} target='blank'>+91- 6382617206</a></p>
                  </div>
                </div>
              </div>
    
            </div>
    
            <div className="col-lg-7 col-md-12 acadamy-map ">
            
            <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d597712.2965460258!2d77.60393279645406!3d10.207198323224597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m0!4m5!1s0x3b00c92746c9e4b1%3A0xfa467471b80cfa12!2sSahaya%20Annai%20Community%20College%2C%20Chellaiya%20Nagar%2C%20Koodal%20Nagar%2C%20Madurai%2C%20Tamil%20Nadu%20625018!3m2!1d9.9632179!2d78.09857699999999!5e0!3m2!1sen!2sin!4v1713175589573!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" style={{ width: '100%', height: '100%' }} referrerpolicy="no-referrer-when-downgrade"></iframe>                  
            </div>
          
    
          </div>
    
        </div>
      </section>

    </div>
  )
}

export default Acadamy;
