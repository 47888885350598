
import bg from "./assets/images/client.jpg";
import client from './assets/images/doctor-icon.jpg';
import healthcare from './assets/images/our-doctor.png'
import {useEffect} from 'react';
import { FaHandHoldingMedical } from "react-icons/fa6";
import { FaHouseChimneyMedical } from "react-icons/fa6";
import { FaSuitcaseMedical } from "react-icons/fa6";
import underline from "./assets/images/underline.png";
import { FaUserDoctor } from "react-icons/fa6";

function Ourdoctor(){
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
    return(
      <>
      <section id="hero" class="d-flex align-items-center">
    <div class="container">
      <div className="row">
        <div className="col-md-4 col-lg-12">
      <p>We are here for your care.</p>
      <h1>BEST CARE & <br/> BEST DOCTORS </h1>
      </div>
      <div className="col-lg-12 hero-bg"></div>
      </div>
    </div>
  </section>
  {/* <section id="why-us" class="why-us">
      <div class="container">

        <div class="row">
          <div class="col-lg-4  d-flex align-items-stretch">
            <div class="content">
              <h3>Why Choose Us</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit
                Asperiores dolores sed et. Tenetur quia eos. Autem tempore quibusdam vel necessitatibus optio ad corporis.
              </p>
            </div>
          </div>
          <div class="col-lg-10 d-flex align-items-stretch">
            <div class="icon-boxes d-flex flex-column justify-content-center">
              <div class="row">
                <div class="col-xl-4 d-flex align-items-stretch">
                  <div class="icon-box mt-4 mt-xl-0">
                  <i class="bi bi-heart-pulse-fill"></i>
                    <h4>Corporis voluptates sit</h4>
                    <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
                  </div>
                </div>
                <div class="col-xl-4 d-flex align-items-stretch">
                  <div class="icon-box mt-4 mt-xl-0">
                  <i class="bi bi-lungs-fill"></i>
                    <h4>Ullamco laboris ladore pan</h4>
                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
                  </div>
                </div>
                <div class="col-xl-4 d-flex align-items-stretch">
                  <div class="icon-box mt-4 mt-xl-0">
                  <i class="bi bi-virus"></i>
                    <h4>Labore consequatur</h4>
                    <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section> */}
        <div class="bg-img">
        <section class="mt-5 ">
          <div class="container">
            
             
            {/* <div className="heading_container heading_center">
           
          <div className="nurseHome-icon pt-5">
          <h1 className=" pt-5 -pl-5   about-h1  ">
            Our Doctors List
          </h1>
        </div>
              <img src={underline} style={{ width: "200px",  }}></img>
              
            </div> */}
           
            <div class="row g-5 mt-3 doctor-content">
              <div class="col-md-6 col-sm-12 col-lg-3 " data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />                 
                  </div>
                  <div class="team-content">
                  <h3 class="title">Dr. T.ARUN PRASATH <br/> <span style={{fontSize:'10px'}}>MD, FIPM, F.DIAB,</span></h3>
                    <span class="post"> தலைமை மருத்தவ அதிகாரி (CMO) </span>
                    <span class="post">  அதிதீவிர சிகிச்சை, வலி நிவாரணம் மற்றும் மயக்கவியல் நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                  <h3 class="title">Dr. S.S. SATHISH <br/><span style={{fontSize:'10px'}}>MD., ANAESTHETIST</span></h3>
                    <span class="post"> மயக்கவியல் நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                  <h3 class="title">Dr.SYED AHAMED KAMIL <br/> <span style={{fontSize:'10px'}}>M.S., (GENERAL SURGEON), FIAGES</span></h3>
                    <span class="post">பொது அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3 " data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. VALLI DEVI <br/> <span style={{fontSize:'10px'}}>MD., PHYSICIAN</span></h3>
                    <span class="post"> பொது மருத்துவ சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. AHAMED KALIL <br/> <span style={{fontSize:'10px'}}>MD., PHYSICIAN</span></h3>
                    <span class="post">பொது மருத்துவ சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. SARAVANAN <br/> <span style={{fontSize:'10px'}}>MD, DM (CARDIOLOGIST)</span></h3>
                    <span class="post"> இருதய நோய் சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr.MALAI RAMAN  <br/> <span style={{fontSize:'10px'}}>MBBS, MS (GENERAL SURGEON)</span></h3>
                    <span class="post">  பொது அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3 " data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. RENGANATHAN <br/> <span style={{fontSize:'10px'}}>MBBS, MS (GENERAL SURGEON)</span></h3>
                    <span class="post">  பொது அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. ABIRAMI <br/> <span style={{fontSize:'10px'}}>MBBS, MS (OG)</span></h3>
                    <span class="post">  மகப்பேறு மகளிர் மற்றும் குழந்தையின்மை சிகிச்சை  நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. RAJANALINI <br/> <span style={{fontSize:'10px'}}>DGO, MD, FRM</span></h3>
                    <span class="post">(FELLOWSHIP IN REPRODUCTIVE MEDICINE)</span>
                    <span class="post"> மகப்பேறு மகளிர் மற்றும் குழந்தையின்மை சிகிச்சை சிறப்பு நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. RAJA RAJAN <br/> <span style={{fontSize:'10px'}}>MS, MCH (NEURO), FNS CONSULTANT BRAIN & SPINE SURGEON</span></h3>
                    <span class="post">  மூளை, தண்டுவட நரம்பியல் அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3 " data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. SURESH ΚΑΝΝΑΝ <br/> <span style={{fontSize:'10px'}}>MD (GM), DM (NEURO) </span></h3>
                    <span class="post"> மூளை, தண்டுவட நரம்பியல்  நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. STANISH <br/> <span style={{fontSize:'10px'}}>DA, MS (ORTHOPEDICS)</span></h3>
                    <span class="post">  எலும்பு முறிவு, மூட்டு மாற்று அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. K.VINOTH KUMAR <br/>  <span style={{fontSize:'10px'}}>MBBS, DNB (ORTHO) MNAMS, FIJR</span></h3>
                    <span class="post"> எலும்பு முறிவு, மூட்டு மாற்று அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. BALAMURUGAN <br/> <span style={{fontSize:'10px'}}>MBBS., DO (ORTHOPEDIC)</span></h3>
                    <span class="post">  எலும்பு முறிவு, மூட்டு மாற்று அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3 " data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. PRAVIN KUMAR <br/> <span style={{fontSize:'10px'}}>MS, MCH (UROLOGY)</span></h3>
                    <span class="post">  சிறுநீரக அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. VIGNESH <br/> <span style={{fontSize:'10px'}}>MD (PULMONOGIST)</span></h3>
                    <span class="post"> நுரையீரல் சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. MURALI KANNAN <br/> <span style={{fontSize:'10px'}}>MS, MCH (ONCO)</span></h3>
                    <span class="post"> புற்றுநோய் சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. A.N.GURUMOORTHY <br/> <span style={{fontSize:'10px'}}>MS, MCH (SURGICAL ONCOLOGIST)</span></h3>
                    <span class="post"> புற்றுநோய் அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3 " data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. P.B.SRIRAM <br/> <span style={{fontSize:'10px'}}>MD, DM (GASTRO) MEDICAL GASTROLOGIST, HEPATOLOGIST, INTERVENTONAL ENDOSCOPIST</span></h3>
                    <span class="post"> வயிறு, குடல், கல்லீரல் சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. ASHOK KUMAR <br/> <span style={{fontSize:'10px'}}>MS, MCH (GASTRO)</span></h3>
                    <span class="post"> வயிறு, குடல், கல்லீரல் சிறப்பு அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr.S.MADHU MATHI <br/> <span style={{fontSize:'10px'}}>MD (PSY)</span></h3>
                    <span class="post">  மனநல மருத்தவ மருத்துவர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. NITHYA <br/> <span style={{fontSize:'10px'}}>M.D, DM (NEPHROLOGIST)</span></h3>
                    <span class="post">   சிறுநீரக மருத்தவ நிபுணர் </span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title"> Dr. VIJAY GANESH <br/> <span style={{fontSize:'10px'}}>MS, MCH (PLASTIC SURGERY)</span></h3>
                    <span class="post"> பிளாஸ்டிக் அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. SHYAM ANANDH <br/> <span style={{fontSize:'10px'}}>MD, DCH (PEDIATRICIAN) </span></h3>
                    <span class="post">  குழந்தைகள் சிறப்பு மருத்துவ நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title"> Dr. CHRISTOPER <br/>  <span style={{fontSize:'10px'}}>MBBS, DCH (PEDIATRICIAN) </span></h3>
                    <span class="post">குழந்தைகள் சிறப்பு மருத்துவ நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title"> Mrs. PRAVEENA <br/> <span style={{fontSize:'10px'}}>BPT., PGDEN., MIAP.,</span></h3>
                    <span class="post"> இயன்முறை (பிசியோதெரபி) மருத்துவ நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Mr. JEYA SEELAN <br/> <span style={{fontSize:'10px'}}>MPT (ORTHO) MIAT, CDNT</span></h3>
                    <span class="post">   இயன்முறை (பிசியோதெரபி) மருத்துவ நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. SAMSON RAJKUMAR <br/> <span style={{fontSize:'10px'}}>BDS.,</span></h3>
                    <span class="post">  பல் மருத்துவ நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. PRIYA DHARSHINI <br/> <span style={{fontSize:'10px'}}>MDS.,</span></h3>
                    <span class="post">   பல் மருத்துவ நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. NITHYA GANESH <br/> <span style={{fontSize:'10px'}}>MDS (ORAL & MAXILLO FACIAL SURGEON)</span></h3>
                    <span class="post">  பல் மருத்துவ அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. ANBU SELVAN <br/> <span style={{fontSize:'10px'}}>MDS (DENTAL ORTHO)</span></h3>
                    <span class="post">  பல் மருத்துவ அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. THAVAMANI <br/> <span style={{fontSize:'10px'}}>MBBS., MS., (ENT) & HEAD & NECK SURGERY</span></h3>
                    <span class="post">   காது, மூக்கு, தொண்டை அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. MAHABOOBKHAN <br/> <span style={{fontSize:'10px'}}>MDRD., DNB., MICR.,</span></h3>
                    <span class="post">  கதீர்வீச்சு மருத்துவ நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              {/* <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. VINOTH KUMAR  <span style={{fontSize:'10px'}}>MBBS.,</span></h3>
                    <span class="post">  நிலைய மருத்துவ அதிகாரி (DMO)</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div> */}
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. G.MADHAVAN  <br/> <span style={{fontSize:'10px'}}>MBBS.,</span></h3>
                    <span class="post">  நிலைய மருத்துவ அதிகாரி (PMO)</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title"> Dr. SABARISH <br/> <span style={{fontSize:'10px'}}>MS, MCH (VASCULAR)</span></h3>
                    <span class="post"> இரத்தநாள அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              {/* <div class="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. AYYANAR  <span style={{fontSize:'10px'}}>MBBS.,</span></h3>
                    <span class="post"> நிலைய மருத்துவ அதிகாரி (DMO)</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div> */}
            
              
            </div>
          
          </div>
        </section>
      </div>
      </>
    );
};
export default Ourdoctor;