import React, { useState } from 'react';
import { useFormik } from 'formik';
import { basicSchema } from "./Formvalidation";
import { useEffect, useRef } from 'react';
import underline from "./assets/images/underline.png";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
// import contactbg from './Assets/contact-bg.png';
// import formvalidatio


const onSubmit = (values, actions) => {
  console.log(values)
  console.log(actions);
  ;
}

function Contact() {
  const currentDate = new Date().toISOString().slice(0, 10);


  const { values, handleChange, touched, handleBlur, errors, handleSubmit } = useFormik({
    initialValues: {
      name: '',
      phonenumber: '',
      servicename: '',
      // email: '',
      // password: '',
      // message:'',
      date: currentDate, // Set current date as the default value
      time: 'AM', // Default time value
      clockTime: '', // Default clock time value
    },
    validationSchema: basicSchema,
    onSubmit,
  });
  console.log(touched);


  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [servicename, setServicename] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [clocktime, setClocktime] = useState('');
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [message, setMessage] = useState('');


  const phonenumber = "06382617206";

  const sendWhatsApp = () => {


    const message = `  Name : ${values.name} \n
    Phone Number : ${values.phonenumber}\n
    Service Name : ${values.servicename}\n
    Date: ${values.date}\n
    Time: ${values.time}\n
    Clock Time: ${values.clockTime}\n\n`;


    // Encode the message for the URL
    const encodedMessage = encodeURIComponent(message);

    // Construct the WhatsApp URL with the encoded message
    const url = `https://wa.me/${phonenumber}?text=${encodedMessage}`;

    // Open the WhatsApp URL in a new tab
    window.open(url, '_blank').focus();
  };

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 20) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const counterRef = useRef(null);

  useEffect(() => {
    const animateCounter = () => {
      const counters = document.querySelectorAll('.counter-value');
      counters.forEach((counter) => {
        const updateCounter = () => {
          const targetValue = +counter.getAttribute('data-target');
          const count = +counter.innerText;

          const increment = targetValue / 100;

          if (count < targetValue) {
            counter.innerText = Math.ceil(count + increment);
            setTimeout(updateCounter, 35);
          } else {
            counter.innerText = targetValue;
          }
        };

        updateCounter();
      });
    };

    animateCounter();
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);




  return (
    <div id="Contact ">
      <section className="contact_section layout_padding  contact-bg contact-layout">
        <div className="container">
          <div className="heading_container heading_center ">
            <h2 >
              Contact us
            </h2>
            <img src={underline} style={{ width: "300px" }}></img>
          </div>
          <div className="row ">
            <div className="col-md-6" data-aos="fade-right">

              <div className="form_container contactus-form">
                <div className="form-container">
                  <div className="form-icon">
                    <i className="bi bi-envelope-open-fill"></i>
                  </div>
                  <form className="form-horizontal" >
                    <div className="heading_container">
                      <h2>
                        Get In Touch
                      </h2>
                    </div>
                    <div className="form-group">
                      <label className='pl-2'>Name <span style={{ color: 'red' }}>*</span></label> <br />
                      <span className="input-icon"><i className="bi bi-person-fill"></i></span>
                      <input class="border-0" type="text" id='name' placeholder="Enter your name"
                        className={errors.name && touched.name ? 'input-error' : 'form-control'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name} />
                      {errors.name && touched.name && <p className='error'>{errors.name}</p>}
                    </div>
                    <div className="form-group">
                      <label className='pl-2'>Phone Number <span style={{ color: 'red' }}>*</span></label> <br />
                      <span className="input-icon"><i className="bi bi-telephone-fill"></i></span>
                      <input type="number" id='phonenumber' placeholder="Enter your phone number"
                        className={errors.phonenumber && touched.phonenumber ? 'input-error' : 'form-control'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phonenumber} />
                      {errors.phonenumber && touched.phonenumber && <p className='error'>{errors.phonenumber}</p>}
                    </div>
                    <div className="form-group">
                      <label className='pl-2'>Service you are looking for <span style={{ color: 'red' }}>*</span></label> <br />
                      <span className="input-icon"><i class="bi bi-person-fill-add"></i></span>
                      <input class="border-0" type="text" id='servicename' placeholder="Ortho / IVF / Cardio / Neuro etc"
                        className={errors.servicename && touched.servicename ? 'input-error' : 'form-control'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.servicename} />
                      {errors.servicename && touched.servicename && <p className='error'>{errors.servicename}</p>}
                    </div>
                    <div className="form-group">
                      <label className='pl-2'>Preferred time for call back</label> <br />
                      <p className='pl-2 ' style={{ fontSize: '12px', fontWeight: 'bold', color: '#2e2f62' }}>Leave blank for an immediate call back</p>
                      {/* <span className="input-icon"><i className="bi bi-person-fill"></i></span> */}
                      <input
                        type="date"
                        id="date"
                        placeholder="Select Date"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.date}
                      />
                      {errors.servicename && touched.servicename && <p className='error'>{errors.servicename}</p>}

                    </div>
                    <label className='pl-2'>Select Time</label>
                    <div className="form-group d-flex ">
                   
                      <input
                        type="time"
                        id="clockTime"
                        placeholder="Select Time"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.clockTime}
                      />
                      <select
                        id="time"

                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.time}  >

                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                      </select>
                    </div>

                    {/* <div  className="form-group">
                       <span className="input-icon" ><i className="bi bi-envelope-fill"></i></span>
                       <input type="email"  id="email" placeholder="Email Address"
                        className={errors.email && touched.email? 'input-error': 'form-control'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email} />
                        {errors.email && touched.email && <p className='error'>{errors.email}</p>} 
                    </div>
                    <div className="form-group">
                       <span className="input-icon"><i className="bi bi-lock-fill"></i></span>
                       <input  type="password" id='password' placeholder="Password"
                         className={errors.password && touched.password? 'input-error': 'form-control'}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         value={values.password} />
                         {errors.password && touched.password && <p className='error'>{errors.password}</p>}
                    </div> */}
                    {/* <div className="form-group">
                       <textarea  rows="4" cols="120" placeholder="Message" id='message'
                        className={errors.message&& touched.message? 'input-error ': 'form-control'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message} ></textarea>
                        {errors.message && touched.message && <p className='error'>{errors.message}</p>}
                    </div> */}
                    <button className="btn signin mt-5" onClick={sendWhatsApp}>Submit <i className="bi bi-arrow-right"></i></button>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6" data-aos="fade-left" style={{ border: "3px solid #2e2f62" }}>


              <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d177848.56396326746!2d78.01275786624014!3d9.939351876848388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3b00c8b1ea10f505%3A0x5d28a700dfd53adb!2sAlanganallur%20Main%20Road%2C%20Chellaiya%20Nagar%2C%20Koodal%20Nagar%2C%20Madurai%2C%20Tamil%20Nadu%20625018!3m2!1d9.9629219!2d78.0983009!5e0!3m2!1sen!2sin!4v1711616432910!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{ width: '100%', height: '100%' }}></iframe>
            </div>

          </div>

        </div>
      </section>

      {/* arrow start */}
      <div className="read-more" >
        <a href="#" class="downarrow-logo"
          onClick={scrollToTop}
          id="myBtn"
          title="Go to top"
          style={{ display: isVisible ? 'block' : 'none' }}>
          <i class="bi bi-arrow-up-circle"></i></a>
      </div>
      {/* arrow end */}

    </div>


  );
};
export default Contact;