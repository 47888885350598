import React from 'react'

const demo = () => {
  return (
    <div>
        <section class="section">
    <div class="container">
      <h1>Choose Pricing Plan</h1>
      <div class="row">
        <div class="col-md-4 p-md-0">
          
          <div class="table-default table1 grad1">
           
            <div class="table__header">
              <h2 class="table__header--title">Basic</h2>
              <p data-price="120" class="table__header--price">
                <span>$</span> 120
              </p>
            </div>
            
            <div class="table__content">
             
              <ul class="table__content--list">
                <li>Limited Times Marketing</li>
                <li>500 Anylytics Campaings</li>
                <li>250,000 crawled Page</li>
                <li class="muted">Unlimited Updates</li>
                <li class="muted">Free Website Design</li>
              </ul>
              
            </div>
          
            <div class="table__footer">
              <a href="#" class="button">Get Started</a>
            </div>
            
          </div>
        
        </div>
        <div class="col-md-4 p-md-0">
         
          <div class="table-default table1 recommended grad2">
           
            <div class="table__header">
              <h2 class="table__header--title">Standard</h2>
              <p data-price="250" class="table__header--price">
                <span>$</span> 250
              </p>
            </div>
          
            <div class="table__content">
              
              <ul class="table__content--list">
                <li>Limited Times Marketing</li>
                <li>500 Anylytics Campaings</li>
                <li>250,000 crawled Page</li>
                <li>Unlimited Updates</li>
                <li class="muted">Free Website Design</li>
              </ul>
             
            </div>
            
            <div class="table__footer">
              <a href="#" class="button">Get Started</a>
            </div>

          </div>
        
        </div>
        <div class="col-md-4 p-md-0">
         
          <div class="table-default table1 grad3">
           
            <div class="table__header">
              <h2 class="table__header--title">Premium</h2>
              <p data-price="520" class="table__header--price">
                <span>$</span> 520
              </p>
            </div>
           
            <div class="table__content">
             
              <ul class="table__content--list">
                <li>Limited Times Marketing</li>
                <li>500 Anylytics Campaings</li>
                <li>250,000 crawled Page</li>
                <li>Unlimited Updates</li>
                <li>Free Website Design</li>
              </ul>
             
            </div>
          
            <div class="table__footer">
              <a href="#" class="button">Get Started</a>
            </div>
          
          </div>
         
        </div>
      </div>
    </div>
  </section>

  
  <section class="section section-grad">
    <div class="container">
      <h1>Choosing Pricing Plan</h1>
      <div class="row">
        <div class="col-md-4">
          
          <div class="table-default table3">
           
            <div class="table__header">
              <img class="table__header--icon" src="https://image.ibb.co/gPbfdG/table3_basic.png" alt="Basic Icon" />
              <h2 class="table__header--title">Basic</h2>
            </div>
            
            <div class="table__content">
             
              <ul class="table__content--list">
                <li>Limited Times Marketing</li>
                <li>500 Anylytics Campaings</li>
                <li>250,000 crawled Page</li>
                <li class="muted">Unlimited Updates</li>
                <li class="muted">Free Website Design</li>
              </ul>
             
            </div>
           
            <div class="table__footer">
              <p data-price="120" class="table__header--price">
                120
                <span>$</span>
              </p>
              <a href="#" class="button">
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              </a>
            </div>
           
          </div>
         
        </div>
        <div class="col-md-4">
         
          <div class="table-default table3 recommended">
            
            <div class="table__header">
              <img class="table__header--icon" src="https://image.ibb.co/hBfj5w/table3_premium.png" alt="Standard Icon" />
              <h2 class="table__header--title">Standard</h2>
            </div>
           
            <div class="table__content">
             
              <ul class="table__content--list">
                <li>Limited Times Marketing</li>
                <li>500 Anylytics Campaings</li>
                <li>250,000 crawled Page</li>
                <li>Unlimited Updates</li>
                <li class="muted">Free Website Design</li>
              </ul>
             
            </div>
           
            <div class="table__footer">
              <p data-price="250" class="table__header--price">
                250
                <span>$</span>
              </p>
              <a href="#" class="button">
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              </a>
            </div>
            
          </div>
         
        </div>
        <div class="col-md-4">
       
          <div class="table-default table3">
          
            <div class="table__header">
              <img class="table__header--icon" src="https://image.ibb.co/eEytyG/table3_standard.png" alt="Premium Icon" />
              <h2 class="table__header--title">Premium</h2>
            </div>
            
            <div class="table__content">
            
              <ul class="table__content--list">
                <li>Limited Times Marketing</li>
                <li>500 Anylytics Campaings</li>
                <li>250,000 crawled Page</li>
                <li>Unlimited Updates</li>
                <li>Free Website Design</li>
              </ul>
              
            </div>
           
            <div class="table__footer">
              <p data-price="520" class="table__header--price">
                520
                <span>$</span>
              </p>
              <a href="#" class="button">
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              </a>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
  </section>
 
 
    </div>
  )
}

export default demo




